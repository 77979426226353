<template>
    <div class="sub-heading-text mt-0 mb-0 d-flex">
        <div>
            <slot name="title-buttons"></slot>
        </div>
        <div v-if="!noHeader" class="align-items-center flex-grow-1 text-truncate">
            <!-- any text in this slot will be truncated to fit the available space -->
            <slot name="title"></slot>
        </div>
        <div v-else class="align-items-center flex-grow-1 text-truncate">
            <slot name="title"></slot>
        </div>

        <span class="d-inline-flex ms-auto">
            <slot name="buttons"></slot>
        </span>
        
    </div>
</template>

<script>
export default {
    props:{
        noHeader:{
            type: Boolean,
            default: false,
        }
    },
    data:() => ({}),
    computed:{},
    methods:{},
}
</script>

<style>
/* .custom-card-header button{
    height: 24px !important;
    white-space: nowrap;
}
.custom-card-header span{
    white-space: nowrap;
} */


</style>