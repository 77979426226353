<!--
@Author: Calvin Green <calvin>
@Date:   2021-12-14T11:24:47+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2021-12-16T08:05:23+02:00
@Copyright: Diversitude 2021
-->

<template>
    <Layout>
        <PageHeader v-if="false" :title="title" :items="items" />
        <AddEmployee v-if="hasCoreDataLoaded && has_loaded" />
        <b-overlay :show="!has_loaded" no-wrap></b-overlay>
    </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import AddEmployee from '@/components/people/components/employees/addEmployee.vue'
import {
    levelComputed,
    peopleMethods,
    peopleComputed,
} from "@/state/helpers";
export default {
    name: "Admin",
    components: {
        Layout,
        PageHeader,
        AddEmployee,
    },
    data:() => ({
        title: "New User Wizard",
        items: [
            {
                text: "Home",
                href: "/",
            },
            {
                text: "People",
                active: true,
            },
        ],
        has_loaded: false,
    }),
    computed: {
        ...levelComputed,
        ...peopleComputed,
        hasCoreDataLoaded(){
            if(this.levels != null){
                return true;
            }
            else{
                return false;
            }
        }
    },
    methods:{
        ...peopleMethods,
        //*!DELETE AFTER NEW USERS SCREEN HAS BEEN ADDED
        saveUser(){
            this.newUser.personal_email = this.newUser.email;
            this.newUser.level_id = this.selected_level.id;

            window.axios.post('api/people/newemployee', this.newUser)
            .then(()=>{
                this.newUser.name = '';
                this.newUser.email = '';
                this.newUser.password = '';
            })
        },
        loadDataAsPromise(){
            //* load component data and then set a flag to display components
            let promises = [
                // spam the requests haha
                this.loadHiringData(this.selected_level.id),
                this.loadEmployeesData(this.selected_level.id),
                this.loadLevelJobsData(this.selected_level.id),
               // this.loadOnboardingData(this.selected_level.id),
                //this.loadOffboardingData(this.selected_level.id),
            ];
            Promise.all(promises)
            .then(()=>{
                this.has_loaded = true;
            })
            .catch(()=>{
                alert('One or more requests failed..')
                this.has_loaded = true;
            })
        }
    },
    mounted(){
        if(this.selected_level == null){
            this.$router.push('/people');
        }
        else if(this.levelJobsData.length == 0){
            this.loadDataAsPromise(this.selected_level.id);
        }
        else{
            this.has_loaded = true;
        }
    },
}
</script>

<style>

</style>
